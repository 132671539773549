<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        vfab
        depressed
        x-small
        icon
        v-bind="attrs"
        v-on="on"
        @click="prepareInfo"
      >
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          <span text-h4 class="hqsOrange--text text">{{ title }}</span>
          <span v-if="isMagFieldLegendMsg" text-h4 class="hqsOrange--text text"
            >Magnetic Field Colors and Size</span
          >
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div v-if="isMagFieldLegendMsg">
          <v-card-subtitle class="font-weight-bold text">
            Size
          </v-card-subtitle>
          <v-row class="pl-3">
            <p class="text">
              The size of a site corresponds to the length of the vector [Bx,
              Bx, Bz] and therefore shows the strength of the magnetic fild.
            </p>
          </v-row>
          <v-card-subtitle class="font-weight-bold text">
            Colors
          </v-card-subtitle>
          <v-row class="pl-3">
            <p class="text">
              The coloring of a site is the result of mixing the colors of Bx,
              By and Bz.
            </p>
          </v-row>

          <br />
          <v-row>
            <v-col cols="5">
              <v-img :src="require('../../assets/normalColors.png')"></v-img>
            </v-col>
            <v-col cols="7">
              <p class="text">
                If only one value is set with a positive number, the resulting
                color is either pure red, green or blue.
              </p>
            </v-col>
          </v-row>
          <v-row class="pt-5">
            <v-col cols="5">
              <v-img :src="require('../../assets/negativeColors.png')"></v-img>
            </v-col>
            <v-col cols="7">
              <p class="text">
                If the only set value is a negative number, the resulting color
                just consitst of the pure color in negative direction.
              </p>
            </v-col>
          </v-row>
          <v-row class="pt-5">
            <v-col cols="5">
              <v-img :src="require('../../assets/mixedColors.png')"></v-img>
            </v-col>
            <v-col cols="7">
              <p class="text">
                If more than one value of Bx, By and Bz is not 0, the resulting
                color is a mix of all the color values.
              </p>
            </v-col>
          </v-row>
        </div>

        <div class="text" v-html="msg"></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    msg: '',
    dialog: false,
    title: '',
    isMagFieldLegendMsg: false,
    msgPos: `Position represents the location of the atom or site in cartesian
          coordinates.`,
    msgMagField: `The Magnetic Field of a site or an Atom represents the on-site
          magnetic field
          <i>[Bx, By, Bz]</i>.`,
    msgAllMagFields: `Within the dialog 'Set All Magnetic Fields' it is possible to set the
          values Bx, By and Bz for the on-site magnetic field of all sites to
          the same values at the same time.`,
    msgTranslation: `Translation represents the reduced coordinates
        that additionaly shift the second atom of the bond by integer multiples
        of the lattice vectors.`,
    msgJValues: `The J-value or Interaction terms (U) represent the energy
        cost of having one electron each on the atoms connected by the bond. 
        They consist of Jz, Jperp, Jcross and Jd.
        Beyond that, the Jd coupling describes an S^+_p S^+_q + S^-_q  S^-_p exchange coupling. 
        In the absence of further S^z breaking terms on has to set mod_Sz=4. Otherwise to mod_Sz=2.`,
    msgAllJValues: `With this dialog it is possible to set the values Jz, Jperp and
        Jcross for the inter-site spin-sipn interactions of all Bonds in this table
        to the same values at the same time.`,
    msgSpinRep: `This variable can be used to control the representation
        of the Spin Operators. Spin Representation has to be a positive integer
          <b><i>n</i></b>.
          It determines the spin quantum number <b><i>s:</i></b>
          <br />
          <b><i>s = n / 2</i></b>`,
    msgVectors: `Lattice-Vectors describe the translation of a unit cell
        in space.
          Lattice-Vector a describes the translation of a unit-cell in direction
          x and Lattice-Vector b the translation in direction y for 2D Lattices.`,
    msgClusterSize: `
          A lattice is constructed by repeating the unit-cell as the base building-block in the
          direction of the lattice vectors. The number of repetitions are defined by the cluster size.
          The minimum value for cluster size x, y is 1.<br>
          <b>Note:</b> For the Lattice Application the system size, defining the repetition of clusters
          in the directions of the lattice vectors is hard-set to [1, 1].
    `,
    msgSpinChannel: `
          The spin channel notation represents the parameters for the spin-dependent onsite-energy.
          <br>
          It is equivalent to the magnetic field notation for it just represents a
          different set of parameters to express the same energy. It is possible to toggle the notation -
          we will automatically compute the conversion for you.
    `,
    msgMagFieldNotation: `
        The magnetic field notationon represents the parameters for onsite energy dependend on the
        site occupation, coupled to the local potential e and the onsite spin magnetization,
        coupled to the onsite magnetic field <i>B = [Bx, By, Bz]</i>.<br>
        <br>
          The magnetic field notation is equivalent to the spin channel notation. It just represents a
          different set of parameters to express the same energy. It is possible to toggle the notation -
          we will automatically compute the conversion for you.
    `,
    msgOnsiteU: `
          This parameter represents the "Hubbard U", i.e. the interaction energy between electrons on
          the same site (only relevant for spinful fermions).
    `,
    msgOnsiteBCS: `
          This parameter represents the onsite BCS pairing.
    `,
    msgTValues: `
        The t-Values comprise of ↑↑, ↑↓, ↓↑, ↓↓. They describe the energy cost associated with moving
        one electron along the bond.
        For spinful electrons these hopping terms can be spin-dependent.
        Every t value can be complex.
        (The hermitian conjugate terms in the Hamiltonian are automatically generated).

    `,
    msgDValues: `
        The D-Values comprise of ↑↑, ↑↓, ↓↑, ↓↓. They describe the anomalous BCS like paring on a bond.
        For spinful electrons these anomalous pairing terms can be spin-dependent.
        Every t value can be complex.
        (The hermitian conjugate terms in the Hamiltonian are automatically generated).
    `,
    msgU: `
        This value describes the energy cost of having one electron each on the atoms connected by the bond.
        It corresponds to the inter-site density-density interaction for spinful fermions.
    `,
    msgBoundaryConditions: `
        Note: If the cluster size is less than [3, 3, 1]
        hard-wall boundary conditions will be applied for each direction with cluster size
        < 3.
    `,
    msgSz: `
        The quantum number with Sz and mod Sz: The Spin Representation n determines the spin
        quantum number, s = n/2.
        In the presence of transverse magnetic fields ( Bx ≠ 0 and/ or By ≠ 0 ),
        Sz is no longer a good quantum number.
        In order to allow for states to break this symmetry,
        please specify the input parameter mod Sz to be non-zero.
        For example, mod Sz = 2 allows Eigenstates to be a superposition of states differing
        by single or multiple spin flips.
        Normally Sz is a half-integer. Spin Systems however uses 2 * Sz for it beeing an integer.
    `,
    msgModSz: `
        The quantum number with Sz and mod Sz: The Spin Representation n determines the spin
        quantum number, s = n/2.
        In the presence of transverse magnetic fields ( Bx ≠ 0 and/ or By ≠ 0 ),
        Sz is no longer a good quantum number.
        In order to allow for states to break this symmetry,
        please specify the input parameter mod Sz to be non-zero.
        For example, mod Sz = 2 allows Eigenstates to be a superposition of states differing
        by single or multiple spin flips. Interaction
        Normally Sz is a half-integer. Spin Systems however uses 2 * Sz for it beeing an integer.
    `,
    msgN: `
         This value describes the number of fermions.
    `,
    msgModN: `
        In the presence of anomalous pairing N as the number of fermions is no longer a good
        quantum number.
        In order to allow for states to break this symmetry please specify the input
        parameter mod_N to be non-zero, usually mod_N = 2.
    `,
    msgDefaultColors: `
        The default color for a fermion lattice site is derived from a
        preset set of 6 colors. The assignment of colors rotates for
        lattices with more then 6 sites with different names.
        Sites with the same name will obtain the same color.`,
  }),

  props: {
    dialogMessage: String,
    info: String,
  },

  methods: {
    close() {
      this.dialog = false
    },

    prepareInfo() {
      if (this.info === 'position') {
        this.msg = this.msgPos
        this.title = 'Position of Sites'
      }
      if (this.info === 'magField') {
        this.msg = this.msgMagField
        this.title = 'Magnetic Fields'
      }
      if (this.info === 'allMagFields') {
        this.msg = this.msgAllMagFields
        this.title = 'Set all Magnetic Fields'
      }
      if (this.info === 'translation') {
        this.msg = this.msgTranslation
        this.title = 'Translation'
      }
      if (this.info === 'jValues') {
        this.msg = this.msgJValues
        this.title = 'J-Values and Interaction'
      }
      if (this.info === 'allJValues') {
        this.msg = this.msgAllJValues
        this.title = 'Set all J-values'
      }
      if (this.info === 'spinRep') {
        this.msg = this.msgSpinRep
        this.title = 'Spin Representation'
      }
      if (this.info === 'vectors') {
        this.msg = this.msgVectors
        this.title = 'Lattice Vectors'
      }
      if (this.info === 'systemSize') {
        this.msg = this.msgClusterSize
        this.title = 'Cluster Size'
      }
      if (this.info === 'spinChannel') {
        this.msg = this.msgSpinChannel
        this.title = 'Spin Channel Notation'
      }
      if (this.info === 'magFieldNotation') {
        this.msg = this.msgMagFieldNotation
        this.title = 'Magnetic Field Notation'
      }
      if (this.info === 'onsiteU') {
        this.msg = this.msgOnsiteU
        this.title = 'Onsite U'
      }
      if (this.info === 'onsiteBCS') {
        this.msg = this.msgOnsiteBCS
        this.title = 'Onsite BCS'
      }
      if (this.info === 'tValues') {
        this.msg = this.msgTValues
        this.title = 't Values'
      }
      if (this.info === 'DValues') {
        this.msg = this.msgDValues
        this.title = 'D Values'
      }
      if (this.info === 'U') {
        this.msg = this.msgU
        this.title = 'U'
      }
      if (this.info === 'boundaryConditions') {
        this.msg = this.msgBoundaryConditions
        this.title = 'Boundary Conditions'
      }
      if (this.info === 'Sz') {
        this.msg = this.msgSz
        this.title = 'Sz'
      }
      if (this.info === 'modSz') {
        this.msg = this.msgModSz
        this.title = 'mod Sz'
      }
      if (this.info === 'N') {
        this.msg = this.msgN
        this.title = 'N'
      }
      if (this.info === 'modN') {
        this.msg = this.msgModN
        this.title = 'mod N'
      }
      if (this.info === 'defaultColors') {
        this.msg = this.msgDefaultColors
        this.title = 'Default coloring of fermion lattice sites'
      }
      if (this.dialogMessage === 'magFieldLegend') {
        this.isMagFieldLegendMsg = true
      }
    },
  },
}
</script>
