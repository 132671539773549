<template>
  <div>
    <h2
      data-cy="site_type_first"
      v-if="latticeType === '' || latticeType === null"
      class="text"
    >
      Please select a Site Type first!
    </h2>
    <v-form ref="form_bonds" v-model="valid_bond" v-else>
      <!-- site id selects -->
      <v-row>
        <v-col cols="6">
          <v-select
            data-cy="from_site"
            dense
            label="From (Site ID)"
            v-model="id_from"
            :items="getSiteIDs"
            :rules="rules_bonds"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            data-cy="to_site"
            dense
            label="To (Site ID)"
            v-model="id_to"
            :items="getSiteIDs"
            :rules="rules_bonds"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-sheet color="darkGrey--text">
            Translation
            <InfoDialog info="translation"></InfoDialog>
          </v-sheet>
        </v-col>
        <v-col cols="4">
          <v-text-field
            data-cy="translation_x"
            dense
            type="number"
            label="x"
            v-model.number="a"
            :rules="rules_between"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            data-cy="translation_y"
            dense
            type="number"
            label="y"
            v-model.number="b"
            :rules="rules_between"
          ></v-text-field>
        </v-col>
      </v-row>

      <div
        v-if="latticeType.includes('spinful') || latticeType.includes('spins')"
      >
        <v-sheet class="text">
          J-values
          <InfoDialog info="jValues"></InfoDialog>
        </v-sheet>
        <v-row class="mt-1">
          <v-col cols="3">
            <v-text-field
              data-cy="jz"
              dense
              label="Jz"
              type="number"
              v-model.number="Jz"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              data-cy="jperp"
              dense
              label="Jperp"
              type="number"
              v-model.number="Jperp"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              data-cy="jcross"
              dense
              label="Jcross"
              type="number"
              v-model.number="Jcross"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              data-cy="jd"
              dense
              label="Jd"
              type="number"
              v-model.number="Jd"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <!-- currently not used, because qolossal does not need it -->
      <!-- <v-row v-if="latticeType.includes('fermions')">
        <v-col cols="1">
          <v-sheet color="darkGrey--text"> U </v-sheet>
        </v-col>
        <v-col cols="1" class="ml-n12">
          <InfoDialog info="U"></InfoDialog>
        </v-col>
        <v-col cols="2" class="ml-n4">
          <v-text-field
            data-cy="U"
            dense
            type="number"
            label="U"
            v-model.number="U"
          ></v-text-field>
        </v-col>
      </v-row> -->

      <v-row v-if="latticeType.includes('spinless')">
        <v-col cols="1">
          <v-sheet color="darkGrey--text"> t </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-text-field
            data-cy="t_r"
            dense
            type="number"
            label="real"
            v-model.number="t_r"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            data-cy="t_i"
            dense
            type="number"
            label="imaginary"
            v-model.number="t_i"
          ></v-text-field>
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="1">
          <v-sheet color="darkGrey--text"> D </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-text-field
            data-cy="D_r"
            dense
            type="number"
            label="real"
            v-model.number="D_r"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            data-cy="D_i"
            dense
            type="number"
            label="imaginary"
            v-model.number="D_i"
          ></v-text-field>
        </v-col>
      </v-row>

      <div v-if="latticeType.includes('spinful')">
        <v-row>
          <v-col cols="2">
            <v-sheet class="text">t-Values</v-sheet>
          </v-col>
          <v-col cols="1" class="ml-n15">
            <InfoDialog info="tValues"></InfoDialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">
            <v-sheet class="text">↑↑</v-sheet>
          </v-col>
          <v-col cols="2">
            <v-text-field
              data-cy="t_uu_r"
              dense
              type="number"
              label="real"
              v-model.number="t_uu_r"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              data-cy="t_uu_i"
              dense
              type="number"
              label="imaginary"
              v-model.number="t_uu_i"
            ></v-text-field>
          </v-col>

          <v-col cols="1"></v-col>
          <v-col cols="1">
            <v-sheet class="text">↑↓</v-sheet>
          </v-col>
          <v-col cols="2">
            <v-text-field
              data-cy="t_ud_r"
              dense
              type="number"
              label="real"
              v-model.number="t_ud_r"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              data-cy="t_ud_i"
              dense
              type="number"
              label="imaginary"
              v-model.number="t_ud_i"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="1">
            <v-sheet class="text">↓↑</v-sheet>
          </v-col>
          <v-col cols="2">
            <v-text-field
              data-cy="t_du_r"
              dense
              type="number"
              label="real"
              v-model.number="t_du_r"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              data-cy="t_du_i"
              dense
              type="number"
              label="imaginary"
              v-model.number="t_du_i"
            ></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
          <v-col cols="1">
            <v-sheet class="text">↓↓</v-sheet>
          </v-col>
          <v-col cols="2">
            <v-text-field
              data-cy="t_dd_r"
              dense
              type="number"
              label="real"
              v-model.number="t_dd_r"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              data-cy="t_dd_i"
              dense
              type="number"
              label="imaginary"
              v-model.number="t_dd_i"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="2">
            <v-sheet class="text">D-Values</v-sheet>
          </v-col>
          <v-col cols="1" class="ml-n15">
            <InfoDialog info="DValues"></InfoDialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1">
            <v-sheet class="text">↑↑</v-sheet>
          </v-col>
          <v-col cols="2">
            <v-text-field
              data-cy="D_uu_r"
              dense
              type="number"
              label="real"
              v-model.number="D_uu_r"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              data-cy="D_uu_i"
              dense
              type="number"
              label="imaginary"
              v-model.number="D_uu_i"
            ></v-text-field>
          </v-col>

          <v-col cols="1"></v-col>
          <v-col cols="1">
            <v-sheet class="text">↑↓</v-sheet>
          </v-col>
          <v-col cols="2">
            <v-text-field
              data-cy="D_ud_r"
              dense
              type="number"
              label="real"
              v-model.number="D_ud_r"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              data-cy="D_ud_i"
              dense
              type="number"
              label="imaginary"
              v-model.number="D_ud_i"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="1">
            <v-sheet class="text">↓↑</v-sheet>
          </v-col>
          <v-col cols="2">
            <v-text-field
              data-cy="D_du_r"
              dense
              type="number"
              label="real"
              v-model.number="D_du_r"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              data-cy="D_du_i"
              dense
              type="number"
              label="imaginary"
              v-model.number="D_du_i"
            ></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
          <v-col cols="1">
            <v-sheet class="text">↓↓</v-sheet>
          </v-col>
          <v-col cols="2">
            <v-text-field
              data-cy="D_dd_r"
              dense
              type="number"
              label="real"
              v-model.number="D_dd_r"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              data-cy="D_dd_i"
              dense
              type="number"
              label="imaginary"
              v-model.number="D_dd_i"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col cols="4"></v-col>
        <v-col cols="4">
          <SuccessAlert
            data-cy="alert"
            v-if="showAlert"
            :type="alertType"
            :message="alertMessage"
          />
        </v-col>
        <v-col cols="4"></v-col>
      </v-row>
      <!-- Add one more checkbox -->
      <v-row>
        <v-col cols="8"></v-col>
        <v-col cols="4">
          <v-checkbox
            v-if="title === 'Add'"
            data-cy="one_more_bond"
            class="pl-3"
            dense
            v-model="addOneMore"
            label="add one more"
            color="hqsOrange"
          ></v-checkbox>
        </v-col>
      </v-row>
      <!-- clear and apply buttons -->
      <v-row>
        <v-col cols="8"></v-col>
        <v-col cols="4">
          <v-btn
            data-cy="clear_bond_form"
            text
            color="darkGrey"
            @click="resetBond"
            >Clear</v-btn
          >
          <v-btn
            data-cy="submit_bond"
            type="submit"
            rounded
            :dark="valid_bond"
            depressed
            color="hqsOrange"
            :disabled="!valid_bond"
            @click.prevent="submitBond"
            >Apply</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
const SuccessAlert = () =>
  import(/* webpackChunkName: "alerts" */ '../general/SuccessAlert.vue')
const InfoDialog = () =>
  import(/* webpackChunkName: "dialogs" */ './InfoDialog.vue')
import { mapFields } from 'vuex-map-fields'
import { mapMutations, mapGetters, mapState } from 'vuex'

export default {
  components: {
    InfoDialog,
    SuccessAlert,
  },

  data: () => ({
    /* form */
    valid_bond: false,
    /* rules for form validation */
    rules_between: [(v) => !!v || v === 0 || 'Required'],
    rules_bonds: [(v) => !!v || v === 0 || 'Required'],
    /* alert for success and error */
    showAlert: false,
    alertMessage: '',
    alertType: '',
  }),

  props: {
    title: String,
  },

  methods: {
    ...mapMutations('lattice', [
      'applyAddedBond',
      'applyEditedBond',
      'setEditBondIndex',
      'updatePlot',
    ]),

    submitBond() {
      this.showAlert = false
      try {
        if (this.title === 'Add') {
          this.applyAddedBond()
        } else {
          this.applyEditedBond()
        }
        this.prepareAlert('Bond applied successfully!', 'success')
      } catch (error) {
        this.prepareAlert('Application of changes failed!', 'error', Infinity)
        this.showAlert = true
      }

      this.$emit('closeDialogOnEdit', this.title)
      this.$refs.form_bonds.resetValidation()
      this.updatePlot()
    },

    resetBond() {
      this.$refs.form_bonds.reset()
    },

    prepareAlert(message, type) {
      this.alertType = type
      this.alertMessage = message
      this.showAlert = true
      if (type === 'success') {
        window.setTimeout(() => {
          this.showAlert = false
        }, this.alertTimeout)
      }
    },
  },

  computed: {
    ...mapGetters('lattice', ['getSiteIDs']),

    ...mapState('lattice', {
      editBondIndex: (state) => state.editBondIndex,
      latticeType: (state) => state.latticeType,
    }),

    ...mapState('backend', {
      alertTimeout: (state) => state.alertTimeout,
    }),

    ...mapFields('lattice', [
      'currentBond.id_from',
      'currentBond.id_to',
      'currentBond.translation.a',
      'currentBond.translation.b',
      'currentBond.Jz',
      'currentBond.Jperp',
      'currentBond.Jcross',
      'currentBond.Jd',
      'currentBond.t_r',
      'currentBond.t_i',
      'currentBond.t_uu_r',
      'currentBond.t_uu_i',
      'currentBond.t_dd_r',
      'currentBond.t_dd_i',
      'currentBond.t_ud_r',
      'currentBond.t_ud_i',
      'currentBond.t_du_r',
      'currentBond.t_du_i',
      'currentBond.D_uu_r',
      'currentBond.D_uu_i',
      'currentBond.D_dd_r',
      'currentBond.D_dd_i',
      'currentBond.D_ud_r',
      'currentBond.D_ud_i',
      'currentBond.D_du_r',
      'currentBond.D_du_i',
      /* currently not used because qolossal does not need it */
      /* 'currentBond.U', */
      'currentBond.D_r',
      'currentBond.D_i',
      'addOneMore',
    ]),
  },

  watch: {
    editBondIndex() {
      if (this.editBondIndex == -1 || this.editBondIndex == '') {
        this.$refs.form_bonds.resetValidation()
      }
    },
  },
}
</script>
