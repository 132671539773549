<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        data-cy="spinful_setall_bonds"
        text
        small
        color="primary"
        v-bind="attrs"
        v-on="on"
        >set all J-values</v-btn
      >
    </template>

    <v-card>
      <v-form ref="form">
        <v-toolbar flat class="hqsOrange--text text">
          <v-toolbar-title>
            <span text-h4>Set J-values &nbsp;</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-sheet color="darkGrey--text" class=" text, mb-5">
                  J-values
                  <InfoDialog info="J-values"></InfoDialog>
                </v-sheet>
                <v-row>
                  <v-col cols="3">
                    <v-text-field
                      data-test="jz-input"
                      dense
                      label="Jz:"
                      type="number"
                      v-model.number="jz"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      dense
                      label="Jperp:"
                      type="number"
                      v-model.number="jperp"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      dense
                      label="Jcross:"
                      type="number"
                      v-model.number="jcross"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      dense
                      label="Jd:"
                      type="number"
                      v-model.number="jd"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="darkGrey">Clear</v-btn>
          <v-btn
            rounded
            dark
            type="submit"
            color="hqsOrange"
            @click.prevent="submitJValues"
            >Apply</v-btn
          >
        </v-card-actions>
      </v-form>
      <div></div>
    </v-card>
  </v-dialog>
</template>

<script>
const InfoDialog = () =>
  import(/* webpackChunkName: "dialogs" */ './InfoDialog.vue')
import { useVuex /* useStore */ } from '@vueblocks/vue-use-vuex'
import { reactive, toRefs, ref } from '@vue/composition-api'

export default {
  components: {
    InfoDialog,
  },

  setup() {
    //const store = useStore();
    const { useMutations } = useVuex()
    const { editJValues } = useMutations('lattice', [
      'editJValues',
    ])

    const event = reactive({
      jz: 0,
      jperp: 0,
      jcross: 0,
      jd: 0,
      dialog: false,
      title: '',
    })

    const form = ref(null)

    function submitJValues() {
      let jValues = [event.jz, event.jperp, event.jcross, event.jd]
      editJValues(jValues)
      reset()
      close()
    }

    function close() {
      event.dialog = false
    }

    function reset() {
      form.value.reset()
    }

    return {
      ...toRefs(event),
      submitJValues,
      close,
      form,
    }
  },
}
</script>
