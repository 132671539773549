<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        small
        color="primary"
        v-bind="attrs"
        v-on="on"
        data-cy="spinful_setall_sites"
        >set all Magnetic Fields</v-btn
      >
    </template>

    <v-card>
      <v-form ref="form">
        <v-toolbar flat class="hqsOrange--text text">
          <v-toolbar-title>
            <span text-h4>Set Magnetic Field &nbsp;</span>
            <small text-caption>for all Sites</small>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-sheet color="darkGrey--text">
                  Magnetic Field
                  <InfoDialog info="magField"></InfoDialog>
                </v-sheet>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      label="Bx:"
                      type="number"
                      v-model.number="Bx"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      label="By:"
                      type="number"
                      v-model.number="By"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      dense
                      label="Bz:"
                      type="number"
                      v-model.number="Bz"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="darkGrey">Clear</v-btn>
          <v-btn
            type="submit"
            dark
            rounded
            depressed
            color="hqsOrange"
            @click.prevent="submitMagneticFields"
            >Apply</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
const InfoDialog = () =>
  import(/* webpackChunkName: "dialogs" */ './InfoDialog.vue')
import { createNamespacedHelpers } from 'vuex'

const { mapMutations } = createNamespacedHelpers('lattice')

export default {
  components: {
    InfoDialog,
  },

  data: () => ({
    Bx: 0,
    By: 0,
    Bz: 0,
    dialog: false,
  }),

  methods: {
    ...mapMutations(['editAllMagneticFields']),

    submitMagneticFields() {
      let field = [this.Bx, this.By, this.Bz]
      this.editAllMagneticFields(field)
      this.$refs.form.reset()
      this.close()
    },
    close() {
      this.dialog = false
    },
  },
}
</script>
